import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Stack, Text } from '@chakra-ui/react';
import { NextPage } from 'next';
import { signIn } from 'next-auth/react';
import { useState } from 'react';

import { baseColor } from '@/lib/constants/constants';

export const Login: NextPage = () => {
  const [input, setInput] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };
  const handleSubmit = async () => {
    setLoading(true);
    setError(false);
    const result = await signIn('email', {
      callbackUrl: `${window.location.origin}/`,
      email: input,
      redirect: false,
    });
    if (result?.error) {
      setError(true);
      setLoading(false);
    } else {
      alert(input + '宛にメールが送信されました。メールからログインをしてください。');
      setLoading(false);
    }
  };

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
    >
      <Stack
        spacing={6}
        w={'full'}
        maxW={'sm'}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}
      >
        <Heading
          lineHeight={1.1}
          fontSize={{ base: '2xl', md: '3xl' }}
        >
          ログイン
        </Heading>
        <FormControl
          id='email'
          isRequired
          isInvalid={error}
        >
          <FormLabel>メールアドレス</FormLabel>
          <Input
            h='50px'
            placeholder='name@medirom.co.jp'
            _placeholder={{ color: 'gray.500' }}
            type='email'
            onChange={(e) => handleEmail(e)}
          />
          <p>{error}</p>
          {error && <FormErrorMessage>メールアドレスが正しくありません。入力し直してください。</FormErrorMessage>}
        </FormControl>
        <Stack spacing={6}>
          {loading && (
            <Button
              isLoading
              loadingText='ログイン'
              spinnerPlacement='start'
              h='50px'
              bg={baseColor}
              color={'white'}
              borderRadius='28px'
              _hover={{ opacity: 0.8 }}
              onClick={handleSubmit}
            >
              ログイン
            </Button>
          )}
          {!loading && (
            <Button
              h='50px'
              bg={baseColor}
              color={'white'}
              borderRadius='28px'
              _hover={{
                opacity: 0.8,
              }}
              onClick={handleSubmit}
            >
              ログイン
            </Button>
          )}
        </Stack>
        <Text fontSize={'12px'}>ログインのお問い合わせはslackにてお願いいたします。</Text>
      </Stack>
    </Flex>
  );
};
