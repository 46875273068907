/**
 * ページングの取得数
 */
export const DEFAULT_TAKE = 20;

/**
 * ポイントがプラスだったときの色
 */
export const plusColor = '#8684FF';
/**
 * ポイントがマイナスだったときの色
 */
export const minusColor = '#FF7878';

/**
 * reraku.jpのブランドカラーから持ってきた
 */
export const baseColor = '#82ae40';
