import { NextPage } from 'next';

import { Login } from '@/features/login/pages/Login';

const Page: NextPage = () => {
  return (
    <>
      <Login />
    </>
  );
};

export default Page;
